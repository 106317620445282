// Angular
import { NgModule, PLATFORM_ID, Inject, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
// firebase
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFirePerformanceModule } from '@angular/fire/compat/performance';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import {
  AngularFireAnalyticsModule,
  ScreenTrackingService,
  UserTrackingService,
  CONFIG,
} from '@angular/fire/compat/analytics';
import {
  AngularFireFunctionsModule,
  REGION,
} from '@angular/fire/compat/functions';
// new firebase
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
// ionic
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { PubliofertasRoutingModule } from './pages/publiofertas/publiofertas-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HelperService } from './services/helper/helper.service';
import { OpenModalGuardService } from './services/open-modal-guard/open-modal-guard.service';
import { UserDataService } from './services/user-data/user-data.service';
import { FunnelGuardService } from './services/funnel-guard/funnel-guard.service';
import { LoginPageModule } from './pages/login/login.module';
import { ConfirmPageModule } from './pages/confirm/confirm.module';
import { OffersService } from './services/offers/offers.service';
import { MembersDataService } from './services/members-data/members-data.service';
import { ContactService } from './services/contact/contact.service';
import { GoogleCloudVisionServiceService } from './services/google-cloud-vision-service/google-cloud-vision-service.service';
import { CompaniesService } from './services/companies/companies.service';
import { ComponentsModule } from './components/components.module';
import { NotificationsService } from './services/notifications/notifications.service';
import { SubscriptionsService } from './services/subscriptions/subscriptions.service';
import { SelectModalPageModule } from './pages/select-modal/select-modal.module';
import { PdfModalPageModule } from './pages/pdf-modal/pdf-modal.module';
import { IframePageModule } from './pages/iframe/iframe.module';
import { OnboardingPageModule } from './pages/onboarding/onboarding.module';
import { PostulationsService } from './services/postulations/postulations.service';
import { SalesModalModule } from './components/sales-modal/sales-modal.module';
import { SalesImageModalModule } from './components/sales-image-modal/sales-image-modal.module';
import { TicketsService } from './services/tickets/tickets.service';
import { PerformanceService } from './services/performance/performance.service';
import { getErrorHandler } from './services/error-monitoring/error-monitoring.service';

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: 'md',
    }),
    AppRoutingModule,
    PubliofertasRoutingModule,
    // old firebase
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireMessagingModule,
    AngularFirePerformanceModule,
    AngularFireFunctionsModule,
    AngularFireAnalyticsModule,
    // pages
    LoginPageModule,
    ConfirmPageModule,
    ComponentsModule,
    SelectModalPageModule,
    IframePageModule,
    PdfModalPageModule,
    OnboardingPageModule,
    SalesModalModule,
    SalesImageModalModule,
    ServiceWorkerModule.register('combined-sw.js', {
      enabled: environment.production || environment.test,
    }),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: ErrorHandler,
      useValue: getErrorHandler,
    },
    { provide: REGION, useValue: 'us-east1' },
    {
      provide: CONFIG,
      useValue: {
        APP_NAME: 'app',
        APP_VERSION: environment.version,
      },
    },
    // new firebase
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideStorage(() => getStorage()),
    provideFirestore(() => getFirestore()),
    ScreenTrackingService,
    UserTrackingService,
    HelperService,
    OpenModalGuardService,
    UserDataService,
    FunnelGuardService,
    OffersService,
    MembersDataService,
    ContactService,
    GoogleCloudVisionServiceService,
    CompaniesService,
    SubscriptionsService,
    NotificationsService,
    PostulationsService,
    TicketsService,
    PerformanceService,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    // si es production
    if (environment.production && !environment.test) {
      // Carga tag manager
      const tagManagerScript = document.createElement('script');
      tagManagerScript.innerHTML = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-TDGK4SQ');
      `;
      document.head.appendChild(tagManagerScript);
      const tagManagerNoScript = document.createElement('noscript');
      tagManagerNoScript.innerHTML = `
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TDGK4SQ"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>
      `;
      document.body.appendChild(tagManagerNoScript);
      // carga el pixel de facebook
      const pixelScript = document.createElement('script');
      pixelScript.innerHTML = `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', ${environment.facebookPixel});
      `;
      document.head.appendChild(pixelScript);
      const pixelNoScript = document.createElement('noscript');
      pixelNoScript.innerHTML = `
        <img height="1" width="1" style="display:none"
        src="https://www.facebook.com/tr?id=${environment.facebookPixel}&ev=PageView&noscript=1"
      `;
      document.head.appendChild(pixelNoScript);
    }
    // agrega el manifest al html segun el ambiente
    const manifestLink = document.createElement('link');
    manifestLink.rel = 'manifest';
    manifestLink.href = environment.production
      ? 'manifest.webmanifest'
      : 'manifest.test.webmanifest';
    document.head.appendChild(manifestLink);
  }
}
